import React from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import CardProyects from "../components/card-proyects";
import proyectsData from "../components/proyectData";

function ProjectsV2() {
  const [activeCard, setActiveCard] = React.useState(0); // Estado para mantener la tarjeta activa
  const varValue = 200;

  const handleCardClick = (index) => {
    setActiveCard(index);
  };

  // Limita los proyectos a solo los primeros 4 usando slice
  const limitedProjects = proyectsData.slice(0, 5);

  return (
    <section className="projects" id="projects">
      <Container>
        <Row className="mb-5" data-aos="fade-up" data-aos-duration="800">
          <span>
            <img
              src="img/icons/arrow.png"
              style={{ width: "25px", marginRight: "10px" }}
              alt="Arrow Icon"
            ></img>
            Nuestros proyectos
          </span>
          <h1>Explora lo que hemos construido a través de los años</h1>
          <p>
            Descubre el legado de excelencia que hemos construido a lo largo de
            los años. Desde impresionantes remodelaciones hasta proyectos
            innovadores, cada obra refleja nuestro compromiso con la calidad y
            la ingeniería de vanguardia.
          </p>
        </Row>
        <Row data-aos="fade-up" data-aos-duration="800">
          <Col md="6" sm="12">
            {limitedProjects.map((project, index) => {
              return (
                <CardProyects
                  key={index}
                  classN={activeCard === index ? "active" : ""}
                  number={project.number}
                  title={project.title}
                  ubi={project.ubi}
                  onClick={() => handleCardClick(index)}
                  transition={varValue * index}
                />
              );
            })}
          </Col>

          <Col
            md="6"
            sm="12"
            className="project-img-container"
            style={{
              backgroundImage: `url(${proyectsData[activeCard].portada})`,
            }}
            data-aos="fade-up"
            data-aos-duration="800"
          ></Col>
          <Row className="mt-5 centered" style={{ width: "100%" }}>
            <div className="d-flex justify-content-center">
              <Link
                to="/proyectos"
                className="btn primary"
                
              >
                Ver todos los proyectos
              </Link>
            </div>
          </Row>
        </Row>
      </Container>
    </section>
  );
}

export default ProjectsV2;
