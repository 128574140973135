import Container from "react-bootstrap/Container";
import {
  IoMailOutline,
  IoMapOutline,
  IoPhonePortraitSharp,
} from "react-icons/io5";
function Solution() {
  return (
    <Container className="mt-5">
      <div className="row">


        <div
          className="col-md-6  col-sm-12 col-lg-6"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="400"
          style={{
            paddingRight: "0",
            paddingLeft: "0",
            zIndex: 100,
            minHeight: "auto",
          }}
        >
          <div className="card-solution">
            <div className="d-flex">
              <h3 className="contact-icon">
                <IoMailOutline />
              </h3>
              <div style={{ marginLeft: "15px" }}>
                <h3>Envíanos Correo</h3>
                <p>contacto@r3ingenieria.com</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6  col-sm-12 col-lg-6"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="400"
          style={{
            paddingRight: "0",
            paddingLeft: "0",
            zIndex: 100,
            minHeight: "auto",
          }}
        >
          <div className="card-solution">
            <div className="d-flex">
              <h3 className="contact-icon">
                <IoMapOutline />
              </h3>
              <div style={{ marginLeft: "15px" }}>
                <h3>Ubícanos</h3>
                <p>Ave. Libre Comercio 111,  Parque Industrial Nuevo Nogales, CP 84094 Nogales, Son.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Solution;
