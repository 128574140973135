import React from "react";
import { Container, Col } from "react-bootstrap";
import DefaultCarousel from "../components/DefaulComponents/Carrousel/DefaultCarousel";
import DefaultCarouselOptimized from "../components/DefaulComponents/Carrousel/DefaultCarouselOptimized";
import Aos from "aos";
import { useEffect } from "react";

function Header({ title, text }) {
  const videos = [
    { src: "/videos/hero/hero4.webm", type: "video/mp4" },
    { src: "/videos/hero/hero1.webm", type: "video/mp4" },
    { src: "/videos/hero/hero2.webm", type: "video/mp4" },
    { src: "/videos/hero/hero3.webm", type: "video/mp4" },
  ];
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="hero " id="home">
      <Container className="content-hero" style={{ zIndex: "500" }}>
        <Col
          sm="10"
          className="card-hero"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div className="line-yellow">
            <div style={{ marginLeft: "15px" }}>
              <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
            </div>
          </div>
          <p>{text}</p>
          <div className="hero-btn-section">
            <a className="btn primary" href="#services">Nuestros servicios</a>

            <a className="btn third" href="#contactus">Contáctanos</a>
          </div>
        </Col>
      </Container>
      <DefaultCarouselOptimized videos={videos} />
    </div>
  );
}

export default Header;
