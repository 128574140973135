import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import ContactForm from "../components/ContactFrom";
import Solution from "./solution";
function CotactUs() {
  return (
    <section
      className="contact-us"
      id="contactus"
      style={{ paddingBottom: "0" }}
    >
      <Container>
        <Row>
          <Col sm="6" data-aos="fade-up" data-aos-duration="800" className="img_container_contact p-4">
            <img src="/img/office_r3.webp" className="img_center_div" style={{width:"100%"}}></img>
            
          </Col>
          <Col
            sm="6"
            className="contact-card p-4"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <span>
              <img
                src="/img/icons/arrow.png"
                style={{ width: "25px", marginRight: "10px" }}
              ></img>
              Contáctanos
            </span>
            <h1>Contáctanos</h1>
            <p>
              Estamos aquí para escucharte y ayudarte en lo que necesites. No
              dudes en ponerte en contacto con nosotros para discutir tus
              proyectos, resolver tus dudas o recibir asesoramiento profesional.
            </p>
            <p>
              Nuestro equipo de expertos está listo para brindarte soluciones a
              medida y asistencia personalizada. Ya sea por teléfono, correo
              electrónico o en persona, estamos comprometidos a proporcionarte
              el mejor servicio posible.
            </p>
            <ContactForm
              placeHolder_nombre={"Nombre"}
              placeHolder_btn={"Contáctanos"}
              placeHolder_correo={"Correo"}
              placeHolder_mensaje={"Mensaje"}
              placeHolder_telefono={"Teléfono"}
            />
          </Col>
        </Row>
      </Container>
      <Solution />
      <div style={{ height: "450px", width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3218.255021900385!2d-110.94892052391552!3d31.29162365840705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaafdd1bec3274033%3A0xd4a0d316093fe733!2sR3%20Ingenieria!5e0!3m2!1ses-419!2smx!4v1712635964592!5m2!1ses-419!2smx"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          height={"100%"}
          width={"100%"}
        ></iframe>
      </div>
    </section>
  );
}

export default CotactUs;
