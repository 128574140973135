const projectsData = [
  {
    id: 1,
    title: "Cuartos Limpios",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/cuartos_limpios/15.jpeg",

    images: [
      "/img/proyectos/cuartos_limpios/1.jpeg",
      "/img/proyectos/cuartos_limpios/2.jpg",
      "/img/proyectos/cuartos_limpios/3.jpg",
      "/img/proyectos/cuartos_limpios/4.jpg",
      "/img/proyectos/cuartos_limpios/5.jpeg",
      "/img/proyectos/cuartos_limpios/6.jpeg",
      "/img/proyectos/cuartos_limpios/7.jpeg",
      "/img/proyectos/cuartos_limpios/8.jpeg",
      "/img/proyectos/cuartos_limpios/9.jpeg",
      "/img/proyectos/cuartos_limpios/10.jpeg",
      "/img/proyectos/cuartos_limpios/11.jpg",
      "/img/proyectos/cuartos_limpios/12.jpg",
      "/img/proyectos/cuartos_limpios/13.jpg",
      "/img/proyectos/cuartos_limpios/14.jpg",
      "/img/proyectos/cuartos_limpios/15.jpeg",
    ],
  },
  {
    id: 2,
    title: "Maniobras",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/maniobras/1.jpeg",

    images: [
      "/img/proyectos/maniobras/1.jpeg",
      "/img/proyectos/maniobras/2.jpeg",
      "/img/proyectos/maniobras/3.jpeg",
      "/img/proyectos/maniobras/4.jpeg",
      "/img/proyectos/maniobras/5.jpeg",
      "/img/proyectos/maniobras/6.jpeg",
      "/img/proyectos/maniobras/7.jpeg",
      "/img/proyectos/maniobras/8.jpeg",
      "/img/proyectos/maniobras/9.jpeg",
      "/img/proyectos/maniobras/10.jpeg",
      "/img/proyectos/maniobras/11.jpeg",
    ],
  },
  {
    id: 3,
    title: "Obra civil",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/obra_civil/1.jpeg",

    images: [
      "/img/proyectos/obra_civil/1.jpeg",
      "/img/proyectos/obra_civil/2.jpeg",
      "/img/proyectos/obra_civil/3.jpeg",
      "/img/proyectos/obra_civil/4.jpeg",
      "/img/proyectos/obra_civil/5.jpg",
      "/img/proyectos/obra_civil/6.jpg",
      "/img/proyectos/obra_civil/7.jpg",
      "/img/proyectos/obra_civil/8.jpg",
      "/img/proyectos/obra_civil/9.jpg",
      "/img/proyectos/obra_civil/11.jpg",
      "/img/proyectos/obra_civil/12.jpg",
      "/img/proyectos/obra_civil/13.jpg",
      "/img/proyectos/obra_civil/14.jpg",
      "/img/proyectos/obra_civil/15.jpg",
      "/img/proyectos/obra_civil/16.jpg",
      "/img/proyectos/obra_civil/17.jpg",
      "/img/proyectos/obra_civil/18.jpg",
      "/img/proyectos/obra_civil/19.jpg",
      "/img/proyectos/obra_civil/20.jpg",
      "/img/proyectos/obra_civil/21.jpg",
      "/img/proyectos/obra_civil/22.jpg",
      "/img/proyectos/obra_civil/23.jpg",
      "/img/proyectos/obra_civil/24.jpg",
      "/img/proyectos/obra_civil/25.jpg",
      "/img/proyectos/obra_civil/26.jpg",
      "/img/proyectos/obra_civil/27.jpg",
      "/img/proyectos/obra_civil/28.jpg",
      "/img/proyectos/obra_civil/29.jpg",
      "/img/proyectos/obra_civil/30.jpg",
      "/img/proyectos/obra_civil/31.jpg",
      "/img/proyectos/obra_civil/32.jpg",
      "/img/proyectos/obra_civil/33.jpg",
      "/img/proyectos/obra_civil/34.jpg",
      "/img/proyectos/obra_civil/35.jpg",
      "/img/proyectos/obra_civil/36.jpg",
      "/img/proyectos/obra_civil/37.jpg",
      "/img/proyectos/obra_civil/38.jpg",
      "/img/proyectos/obra_civil/39.jpg",
      "/img/proyectos/obra_civil/40.jpg",
      "/img/proyectos/obra_civil/41.jpg",
      "/img/proyectos/obra_civil/42.jpg",
      "/img/proyectos/obra_civil/43.jpg",
      "/img/proyectos/obra_civil/44.jpg",
      "/img/proyectos/obra_civil/45.jpg",
      "/img/proyectos/obra_civil/46.jpg",
      "/img/proyectos/obra_civil/47.jpg",
      "/img/proyectos/obra_civil/48.jpg",
      "/img/proyectos/obra_civil/49.jpeg",
      "/img/proyectos/obra_civil/50.jpeg",
      "/img/proyectos/obra_civil/51.jpg",
      "/img/proyectos/obra_civil/52.jpg",
      "/img/proyectos/obra_civil/53.jpg",
      "/img/proyectos/obra_civil/54.jpg",
      "/img/proyectos/obra_civil/55.jpg",
      "/img/proyectos/obra_civil/56.jpeg",
      "/img/proyectos/obra_civil/57.jpeg",
      "/img/proyectos/obra_civil/58.jpeg",
      "/img/proyectos/obra_civil/59.jpeg",
      "/img/proyectos/obra_civil/60.jpg",
      "/img/proyectos/obra_civil/61.jpg",
      "/img/proyectos/obra_civil/62.jpg",
      "/img/proyectos/obra_civil/63.jpg",
      "/img/proyectos/obra_civil/64.jpg",
      "/img/proyectos/obra_civil/65.jpg",
      "/img/proyectos/obra_civil/66.jpeg",
      "/img/proyectos/obra_civil/67.jpg",
      "/img/proyectos/obra_civil/68.jpeg",
      "/img/proyectos/obra_civil/69.jpg",
      "/img/proyectos/obra_civil/70.jpeg",
      "/img/proyectos/obra_civil/71.jpeg",
      "/img/proyectos/obra_civil/72.jpeg",
      "/img/proyectos/obra_civil/73.jpeg",
      "/img/proyectos/obra_civil/74.jpeg",
      "/img/proyectos/obra_civil/75.jpeg",
      "/img/proyectos/obra_civil/76.jpeg",
      "/img/proyectos/obra_civil/77.jpeg",
      "/img/proyectos/obra_civil/78.jpeg",
      "/img/proyectos/obra_civil/79.jpeg",
      "/img/proyectos/obra_civil/80.jpeg",
      "/img/proyectos/obra_civil/81.jpeg",
      "/img/proyectos/obra_civil/82.jpeg",
      "/img/proyectos/obra_civil/83.jpg",
      "/img/proyectos/obra_civil/84.jpg",
      "/img/proyectos/obra_civil/85.jpeg",
      "/img/proyectos/obra_civil/86.jpeg",
      "/img/proyectos/obra_civil/87.jpeg",
      "/img/proyectos/obra_civil/88.jpeg",
      "/img/proyectos/obra_civil/89.jpg",
      "/img/proyectos/obra_civil/90.jpg",
      "/img/proyectos/obra_civil/91.jpg",
      "/img/proyectos/obra_civil/92.jpg",
      "/img/proyectos/obra_civil/93.jpg",
      "/img/proyectos/obra_civil/94.jpeg",
      "/img/proyectos/obra_civil/95.jpeg",
      "/img/proyectos/obra_civil/96.jpeg",
      "/img/proyectos/obra_civil/97.jpeg",
      "/img/proyectos/obra_civil/98.jpeg",
      "/img/proyectos/obra_civil/99.jpg",
      "/img/proyectos/obra_civil/100.jpg",
    ]
  },
  {
    id: 4,
    title: "Trabajos herreria",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/trabajos_herreria/1.jpeg",

    images: [
      "/img/proyectos/trabajos_herreria/1.jpeg",
      "/img/proyectos/trabajos_herreria/2.jpeg",
      "/img/proyectos/trabajos_herreria/3.jpeg",
      "/img/proyectos/trabajos_herreria/4.jpeg",
      "/img/proyectos/trabajos_herreria/5.jpeg",
      "/img/proyectos/trabajos_herreria/6.jpg",
      "/img/proyectos/trabajos_herreria/7.jpg",
      "/img/proyectos/trabajos_herreria/8.jpeg",
      "/img/proyectos/trabajos_herreria/9.jpg",
      "/img/proyectos/trabajos_herreria/10.jpeg",
      "/img/proyectos/trabajos_herreria/11.jpeg",
      "/img/proyectos/trabajos_herreria/12.jpeg",
      "/img/proyectos/trabajos_herreria/13.jpeg",
      "/img/proyectos/trabajos_herreria/14.jpeg",
      "/img/proyectos/trabajos_herreria/15.jpeg",
    ],
  },
  {
    id: 5,
    title: "Trabajos electricos",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/trabajos_electricos/1.jpeg",

    images: [
      "/img/proyectos/trabajos_electricos/1.jpeg",
      "/img/proyectos/trabajos_electricos/2.jpeg",
      "/img/proyectos/trabajos_electricos/3.jpeg",
      "/img/proyectos/trabajos_electricos/4.jpeg",
      "/img/proyectos/trabajos_electricos/5.jpeg",
      "/img/proyectos/trabajos_electricos/6.jpeg",
      "/img/proyectos/trabajos_electricos/7.jpeg",
      "/img/proyectos/trabajos_electricos/8.jpeg",
      "/img/proyectos/trabajos_electricos/9.jpeg",
      "/img/proyectos/trabajos_electricos/10.jpeg",
      "/img/proyectos/trabajos_electricos/11.jpg",
      "/img/proyectos/trabajos_electricos/12.jpg",
      "/img/proyectos/trabajos_electricos/13.jpg",
      "/img/proyectos/trabajos_electricos/14.jpeg",
      "/img/proyectos/trabajos_electricos/15.jpeg",
      "/img/proyectos/trabajos_electricos/16.jpeg",
      "/img/proyectos/trabajos_electricos/17.jpeg",
      "/img/proyectos/trabajos_electricos/18.jpeg",
      "/img/proyectos/trabajos_electricos/19.jpeg",
      "/img/proyectos/trabajos_electricos/20.jpeg",
      "/img/proyectos/trabajos_electricos/21.jpeg",
      "/img/proyectos/trabajos_electricos/22.jpeg",
      "/img/proyectos/trabajos_electricos/23.jpeg",
      "/img/proyectos/trabajos_electricos/24.jpeg",
      "/img/proyectos/trabajos_electricos/25.jpeg",
      "/img/proyectos/trabajos_electricos/26.jpeg",
      "/img/proyectos/trabajos_electricos/27.jpeg",
      "/img/proyectos/trabajos_electricos/28.jpeg",
      "/img/proyectos/trabajos_electricos/29.jpeg",
      "/img/proyectos/trabajos_electricos/30.jpeg",
    ],
  },
  {
    id: 6,
    title: "Remodelación de exterior",
    description: "Transformamos tu espacio exterior con creatividad y precisión. Desde renovaciones hasta mejoras estéticas.",
    portada:"/img/proyectos/1/2.jpg",

    images: [
      "/img/proyectos/1/1.jpg",
      "/img/proyectos/1/2.jpg",
      "/img/proyectos/1/3.jpg",
      "/img/proyectos/1/4.jpg",
      "/img/proyectos/1/5.jpg",
      "/img/proyectos/1/6.jpg",
      "/img/proyectos/1/7.jpg",
      "/img/proyectos/1/8.jpg",
      "/img/proyectos/1/9.jpg",
      "/img/proyectos/1/10.jpg",
      "/img/proyectos/1/11.jpg",
      "/img/proyectos/1/12.jpg",
      "/img/proyectos/1/13.jpg",
      "/img/proyectos/1/14.jpg",
    ],
  },
  {
    id: 7,
    title: "Remodelación de oficinas",
    description: "Renovamos tus espacios de trabajo para potenciar la productividad y el confort. Con diseños innovadores y ejecución experta.",
    portada:"img/about3.jpg",
    portada:"/img/proyectos/2/1.jpg",

    images: [
      "/img/proyectos/2/1.jpg",
      "/img/proyectos/2/2.jpg",
      "/img/proyectos/2/3.jpg",
      "/img/proyectos/2/4.jpg",
      "/img/proyectos/2/5.jpg",
      "/img/proyectos/2/6.jpg",
      "/img/proyectos/2/7.jpg",
      "/img/proyectos/2/8.jpg",
      "/img/proyectos/2/9.jpg",
      "/img/proyectos/2/10.jpg",
      "/img/proyectos/2/11.jpg",
      "/img/proyectos/2/12.jpg",
      "/img/proyectos/2/13.jpg",
      "/img/proyectos/2/14.jpg",
      "/img/proyectos/2/15.jpg",
      "/img/proyectos/2/16.jpg",
      "/img/proyectos/2/17.jpg",
      "/img/proyectos/2/18.jpeg",
      "/img/proyectos/2/19.jpg",
      "/img/proyectos/2/20.jpg",
      "/img/proyectos/2/21.jpg",
      "/img/proyectos/2/22.jpg",
      "/img/proyectos/2/23.jpg",
      "/img/proyectos/2/24.jpg",
      "/img/proyectos/2/25.jpg",
      "/img/proyectos/2/26.jpg",
      "/img/proyectos/2/27.jpg",
      "/img/proyectos/2/28.jpg",
      "/img/proyectos/2/29.jpg",
      "/img/proyectos/2/30.jpg",
      "/img/proyectos/2/31.jpg",
      "/img/proyectos/2/32.jpg",
      "/img/proyectos/2/33.jpg",
      "/img/proyectos/2/34.jpg",
      "/img/proyectos/2/35.jpg",
      "/img/proyectos/2/36.jpg",
    ],
  },
  {
    id: 8,
    title: "Remodelación de cuarto limpio",
    description: "Optimizamos la funcionalidad y la esterilidad de tus cuartos limpios. Con soluciones innovadoras, garantizamos un entorno seguro y controlado para tus procesos sensibles.",
    portada:"/img/proyectos/3/1.jpg",

    images: [
      "/img/proyectos/3/1.jpg",
      "/img/proyectos/3/2.jpg",
      "/img/proyectos/3/3.jpg",
      "/img/proyectos/3/4.jpg",
      "/img/proyectos/3/5.jpg",
      "/img/proyectos/3/6.jpg",
      "/img/proyectos/3/7.jpg",
      "/img/proyectos/3/8.jpg",
      "/img/proyectos/3/9.jpg",
      "/img/proyectos/3/10.jpg",
      "/img/proyectos/3/11.jpg",
      "/img/proyectos/3/12.jpg",
      "/img/proyectos/3/13.jpg",
      "/img/proyectos/3/14.jpg",
      "/img/proyectos/3/15.jpg",
      "/img/proyectos/3/16.jpg",
      "/img/proyectos/3/17.jpg",
    ],
  },
  {
    id: 9,
    title: "Remodelación de baños",
    description: "Modernizamos tus baños con estilo y funcionalidad. Desde renovaciones básicas hasta diseños personalizados.",
    portada:"/img/proyectos/4/1.jpg",
    images: [
      "/img/proyectos/4/1.jpg",
      "/img/proyectos/4/2.jpg",
      "/img/proyectos/4/3.jpg",
      "/img/proyectos/4/4.jpg",
      "/img/proyectos/4/5.jpg",
      "/img/proyectos/4/6.jpg",
      "/img/proyectos/4/7.jpg",
      "/img/proyectos/4/8.jpg",
      "/img/proyectos/4/9.jpg",
    ],
  },
  {
    id: 10,
    title: "Enjarre",
    description: "Renovamos tus paredes con enjarre experto. Con precisión y cuidado, transformamos cualquier espacio en un lienzo limpio y uniforme, listo para tus proyectos de diseño.",
    portada:"img/proyectos/5/1.jpg",
    images: [
      "/img/proyectos/5/1.jpg",
      "/img/proyectos/5/2.jpg",
      "/img/proyectos/5/3.jpg",
      "/img/proyectos/5/4.jpg",
      "/img/proyectos/5/5.jpg",
      "/img/proyectos/5/6.jpg",
      "/img/proyectos/5/7.jpg",
      "/img/proyectos/5/8.jpg",
      "/img/proyectos/5/9.jpg",
      "/img/proyectos/5/10.jpg",
      "/img/proyectos/5/11.jpg",
      "/img/proyectos/5/12.jpg",
    ],
  },
  {
    id: 11,
    title: "Impermeabilización",
    description: "Protegemos tus estructuras con impermeabilización de primera calidad. Nuestros servicios garantizan una barrera efectiva contra la humedad, preservando la integridad y durabilidad de tu propiedad.",
    portada:"img/proyectos/6/1.jpg",
    images: [
      "/img/proyectos/6/1.jpg",
      "/img/proyectos/6/2.jpg",
      "/img/proyectos/6/3.jpg",
      "/img/proyectos/6/4.jpg",
      "/img/proyectos/6/5.jpg",
      "/img/proyectos/6/6.jpg",
      "/img/proyectos/6/7.jpg",
      "/img/proyectos/6/8.jpg",
      "/img/proyectos/6/9.jpg",
      "/img/proyectos/6/10.jpg",
      "/img/proyectos/6/11.jpg",
      "/img/proyectos/6/12.jpg",
      "/img/proyectos/6/13.jpg",
      "/img/proyectos/6/14.jpg",
      "/img/proyectos/6/15.jpg",
    ],
  },
  {
    id: 12,
    title: "Ampliación para lockers",
    description: "Realizamos una ampliación de lockers para ofrecer mayor espacio de almacenamiento a nuestros clientes. El proyecto incluyó diseño personalizado y ejecución experta para garantizar funcionalidad y comodidad.",
    portada:"img/proyectos/7/1.jpg",
    images: [
      "/img/proyectos/7/1.jpg",
      "/img/proyectos/7/2.jpg",
      "/img/proyectos/7/3.jpg",
      "/img/proyectos/7/4.jpg",
      "/img/proyectos/7/5.jpg",
      "/img/proyectos/7/6.jpg",
      "/img/proyectos/7/7.jpg",
      "/img/proyectos/7/8.jpg",
      "/img/proyectos/7/9.jpg",
      "/img/proyectos/7/10.jpg",
      "/img/proyectos/7/11.jpg",
      "/img/proyectos/7/12.jpg",
      "/img/proyectos/7/13.jpg",
      "/img/proyectos/7/14.jpg",
    ],
  },
  {
    id: 13,
    title: "Barandal y bolardos de herrería",
    description: "Fabricamos e instalamos un barandal de herrería para mejorar la seguridad y el estilo de tu propiedad.",
    portada:"img/proyectos/8/1.jpg",
    images: [
      "/img/proyectos/8/1.jpg",
      "/img/proyectos/8/2.jpg",
      "/img/proyectos/8/3.jpg",
      "/img/proyectos/8/4.jpg",
      "/img/proyectos/8/5.jpg",
    ],
  },
  {
    id: 14,
    title: "Remodelación de oficinas Sindicatura",
    description: "Llevamos a cabo una remodelación integral de las oficinas de la Sindicatura. Nuestro trabajo incluyó diseño ergonómico, instalaciones modernas y acabados de alta calidad para mejorar la eficiencia y el ambiente laboral.",
    portada:"img/proyectos/9/1.jpg",
    images: [
      "/img/proyectos/9/1.jpg",
      "/img/proyectos/9/2.jpg",
      "/img/proyectos/9/3.jpg",
      "/img/proyectos/9/4.jpg",
      "/img/proyectos/9/5.jpg",
      "/img/proyectos/9/6.jpg",
      "/img/proyectos/9/7.jpg",
      "/img/proyectos/9/8.jpg",
      "/img/proyectos/9/9.jpg",
      "/img/proyectos/9/10.jpg",
      "/img/proyectos/9/11.jpg",
      "/img/proyectos/9/12.jpg",
      "/img/proyectos/9/13.jpg",
      "/img/proyectos/9/14.jpg",
      "/img/proyectos/9/15.jpg",
      "/img/proyectos/9/16.jpg",
      "/img/proyectos/9/17.jpg",
      "/img/proyectos/9/18.jpg",
      "/img/proyectos/9/19.jpg",
      "/img/proyectos/9/20.jpg",
      "/img/proyectos/9/21.jpg",
      "/img/proyectos/9/22.jpg",
      "/img/proyectos/9/23.jpg",
      "/img/proyectos/9/24.jpg",
      "/img/proyectos/9/25.jpg",
      "/img/proyectos/9/26.jpg",
      "/img/proyectos/9/27.jpg",
    ],
  },
  {
    id: 15,
    title: "Ampliación",
    description: "Realizamos una ampliación estructural para adaptarnos a tus necesidades de espacio. Nuestro enfoque incluye diseño personalizado y construcción eficiente para maximizar la funcionalidad .",
    portada:"img/proyectos/10/1.jpg",
    images: [
      "/img/proyectos/10/1.jpg",
      "/img/proyectos/10/2.jpg",
      "/img/proyectos/10/3.jpg",
      "/img/proyectos/10/4.jpg",
      "/img/proyectos/10/5.jpg",
    ],
  },
];

export default projectsData;
