import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import {
  IoMailOutline,
  IoMapOutline,
  IoPhonePortraitSharp,
} from "react-icons/io5";

function Footer() {
  return (
    <section className="footer">
      <Container>
        <Row>
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="d-flex justify-content-start mb-4">
              <img
                src="/img/logo/logo.svg"
                style={{ height: "6rem", width: "100%" }}
              ></img>
            </div>
            <div className="r3footer">
              <p>
                R3 Ingeniería es una empresa dedicada a ofrecer soluciones
                integrales en ingeniería y construcción.
              </p>
            </div>
          </div>
         
          <div className="col-md-4 col-sm-12 mb-3">
            <Row className="mb-2">
              <h4>Contacto</h4>
            </Row>
            <Col xs="12">
              <div className="d-flex">
                <div>
                  <IoMailOutline className="footer-icon" />
                </div>
                <div className="top-bar-container mail-footer">
                  <p style={{ marginLeft: "10px" }}>
                    contacto@r3ingenieria.com
                  </p>
                </div>
              </div>
            </Col>
            <Col xs="12">
              <div className="d-flex">
                <div className="footer-icon-container">
                  <IoMapOutline className="footer-icon" />
                </div>
                <div
                  className="top-bar-container mail-footer"
                  style={{ paddingRight: "25px" }}
                >
                  <p style={{ marginLeft: "10px" }}>
                  Ave. Libre Comercio 111,  Parque Industrial Nuevo Nogales, CP 84094 Nogales, Son.
                  </p>
                </div>
              </div>
            </Col>
          </div>
          <div className="sections-footer col-md-4 col-sm-12 mb-3">
            <h4>Secciones</h4>
            <a href="#home">Inicio</a>
            <br></br>
            <a href="#about">Nosotros</a>
            <br></br>
            <a href="#services">Servicios</a>
            <br></br>
            <a href="#projects"> Proyectos</a>
            <br></br>
            <a href="#contactus">Contactanos</a>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Footer;
